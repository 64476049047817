import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const RelatedBlogs = ({ tags, category, currentPostId }) => {
  const data = useStaticQuery(graphql`
    query RelatedPosts {
      allContentfulBlogPage {
        nodes {
          id
          title
          slug
          image {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 400 # ✅ サムネイル用に適切なサイズ
              height: 300 # ✅ 4:3 の比率を維持
              quality: 80
              formats: [AUTO, WEBP, AVIF]
            )
          }
          blogTags {
            name
          }
          blogCategory {
            name
          }
        }
      }
    }
  `);

  if (!data?.allContentfulBlogPage?.nodes) {
    return null;
  }

  if ((!tags || tags.length === 0) && !category) {
    return null;
  }

  // ✅ `currentPostId` の null / undefined チェック
  const currentId = currentPostId ? currentPostId.toString().trim() : null;

  if (!currentId) {
    return null;
  }

  let allPosts = data.allContentfulBlogPage.nodes.filter(
    (post) => post.id.toString().trim() !== currentId
  );

  let relatedPosts = allPosts
    .map((post) => ({
      ...post,
      tagMatchCount: post.blogTags
        ? post.blogTags.filter((tag) => tags.includes(tag.name.trim())).length
        : 0,
    }))
    .filter((post) => post.tagMatchCount > 0)
    .sort((a, b) => b.tagMatchCount - a.tagMatchCount)
    .slice(0, 3);

  // ✅ すでに取得済みの記事の ID をリスト化し、現在の記事も確実に除外
  const existingIds = new Set(
    relatedPosts.map((post) => post.id.toString().trim())
  );
  existingIds.add(currentId);

  if (relatedPosts.length < 3 && category) {
    const needed = 3 - relatedPosts.length;
    const categoryPosts = allPosts
      .filter(
        (post) =>
          post.blogCategory?.name === category &&
          !existingIds.has(post.id.toString().trim())
      ) // ✅ 現在の記事も確実に除外
      .slice(0, needed);

    relatedPosts = [...relatedPosts, ...categoryPosts];
  }

  // ✅ 最終チェック: `currentPostId` を確実に除外
  relatedPosts = relatedPosts.filter(
    (post) => post.id.toString().trim() !== currentId
  );

  if (relatedPosts.length === 0) {
    return null;
  }

  return (
    <div className="mt-16">
      <h3 className="text-3xl font-bold mb-6">関連記事</h3>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {relatedPosts.map((post) => (
          <Link
            to={`/blog/${post.slug}`}
            key={post.slug}
            className="block bg-white rounded-xl shadow-lg overflow-hidden transition transform hover:shadow-2xl"
          >
            <div className="w-full aspect-[4/3]">
              <GatsbyImage
                image={getImage(post.image)}
                alt={post.title}
                className="w-full h-full object-cover"
              />
            </div>

            <div className="p-4 flex flex-col">
              <h4 className="text-2xl font-semibold">{post.title}</h4>

              {post.blogTags && post.blogTags.length > 0 && (
                <div className="flex flex-wrap gap-2 mt-2">
                  {post.blogTags.map((tag) => (
                    <span
                      key={tag.name}
                      className="border border-black text-black text-xl font-medium px-2 py-1 rounded-full"
                    >
                      #{tag.name.trim()}
                    </span>
                  ))}
                </div>
              )}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default RelatedBlogs;
