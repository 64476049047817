import React from 'react';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';

const richTextOptions = {
  renderNode: {
    // ✅ 段落（p）
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <p className="mb-10 text-2xl leading-loose tracking-wide text-gray-900">
        {children}
      </p>
    ),

    // ✅ h1（6xl）
    [BLOCKS.HEADING_1]: (node, children) => (
      <h1 className="text-6xl font-bold mt-12 mb-8 text-gray-900">
        {children}
      </h1>
    ),

    // ✅ h2（5xl + 下線）
    [BLOCKS.HEADING_2]: (node, children) => (
      <h2 className="text-5xl font-bold mt-12 mb-8 pb-3 border-b-4 border-gray-800">
        {children}
      </h2>
    ),

    // ✅ h3（4xl）
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className="text-4xl font-semibold mt-10 mb-6 text-gray-900">
        {children}
      </h3>
    ),

    // ✅ 本文内の画像
    [BLOCKS.EMBEDDED_ASSET]: (node, children, options = {}) => {
      const references = Array.isArray(options.references)
        ? options.references
        : [];

      const assetId = node.data.target?.sys?.id;

      if (references.length === 0) {
        console.warn('References is empty:', references);
        return null;
      }

      const asset = references.find(
        (ref) => String(ref.contentful_id) === String(assetId)
      );

      if (!asset || !asset.file) {
        console.warn('Embedded asset not found in references:', assetId);
        return null;
      }

      return (
        <div className="my-8 flex justify-center">
          <img
            src={asset.file.url}
            alt={asset.title || 'Embedded Image'}
            className="rounded-lg shadow-md w-full h-auto max-h-[700px] object-contain"
          />
        </div>
      );
    },

    // ✅ ハイパーリンク
    [INLINES.HYPERLINK]: (node, children) => (
      <a
        href={node.data.uri}
        className="text-blue-600 underline hover:text-blue-800 transition duration-200"
      >
        {children}
      </a>
    ),

    // ✅ **太字（strong）を追加**
    [INLINES.BOLD]: (node, children) => (
      <strong className="font-bold text-gray-900">{children}</strong>
    ),

    // ✅ **箇条書きリスト（ul）を追加**
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className="list-disc pl-5 space-y-2 text-2xl text-gray-900">
        {children}
      </ul>
    ),

    // ✅ **番号付きリスト（ol）を追加**
    [BLOCKS.OL_LIST]: (node, children) => (
      <ol className="list-decimal pl-5 space-y-2 text-2xl text-gray-900">
        {children}
      </ol>
    ),

    // ✅ **リストアイテム（li）を追加**
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <li className="ml-4">{children}</li>
    ),
  },
};

export default richTextOptions;
