import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import BreadcrumbSchema from '../components/BreadcrumbSchema';
import Breadcrumbs from '../components/Breadcrumbs';
import AuthorSchema from '../components/AuthorSchema';
import RelatedBlogs from '../components/templates/RelatedBlogs';
import { Helmet } from 'react-helmet';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import richTextOptions from '../utils/richTextOptions';
import { BLOCKS } from '@contentful/rich-text-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const BlogPost = ({ data }) => {
  const {
    title,
    publishDate,
    updatedAt,
    body,
    image,
    slug,
    seotitle,
    seodescription,
    author,
    blogCategory,
    blogTags,
  } = data.contentfulBlogPage;

  const post = data.contentfulBlogPage;

  const references = body.references || [];

  const richTextOptionsWithReferences = {
    renderNode: {
      ...richTextOptions.renderNode,
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        return richTextOptions.renderNode[BLOCKS.EMBEDDED_ASSET](
          node,
          children,
          { references }
        );
      },
    },
  };

  const breadcrumbList = [
    { name: 'ホーム', url: 'https://www.involve-in.jp/' },
    { name: 'ブログ一覧', url: 'https://www.involve-in.jp/blog' },
    { name: title, url: `https://www.involve-in.jp/blog/${slug}` },
  ];

  return (
    <Layout>
      <BreadcrumbSchema breadcrumbList={breadcrumbList} />
      <Helmet>
        <title>{seotitle || title}</title>
        <meta name="description" content={seodescription} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={`https://www.involve-in.jp/blog/${slug}`} />
        <link
          rel="icon"
          type="image/x-icon"
          href="/images/common/favicon.ico"
        />
        <link
          rel="preload"
          href="/fonts/noto-sans-jp-v53-japanese_latin-regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        {/* Open Graph (OGP: Facebook, LinkedIn) */}
        <meta property="og:title" content={seotitle || title} />
        <meta property="og:description" content={seodescription} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://www.involve-in.jp/blog/${slug}`}
        />
        <meta
          property="og:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seotitle || title} />
        <meta name="twitter:description" content={seodescription} />
        <meta
          name="twitter:image"
          content="https://www.involve-in.jp/images/common/ogp.png"
        />
      </Helmet>
      {author && (
        <AuthorSchema
          author={author}
          title={title}
          publishDate={publishDate}
          updatedAt={updatedAt}
        />
      )}
      <article className="max-w-screen-lg w-full mx-auto my-16 p-12 bg-white bg-opacity-95 shadow-lg rounded-lg text-black">
        <h1 className="h-[64px] text-3xl md:text-5xl font-bold text-center max-w-[800px] leading-[1.2] mb-6 md:mb-12">
          {title}
        </h1>

        {image && (
          <div className="w-full max-w-[800px] mx-auto mb-6 md:mb-12">
            <GatsbyImage
              image={getImage(image)}
              alt={title}
              loading="eager" // ✅ 重要画像はすぐに読み込む
              placeholder="blurred"
              className="rounded-lg shadow-md w-full h-auto max-w-full"
              style={{ aspectRatio: '4/3' }} // ✅ 4:3 に固定
            />
          </div>
        )}

        {/* ✅ カテゴリー & 公開日（1行で表示 & 「|」 で区切る） */}
        <div className="bg-black bg-opacity-80 text-white text-lg px-6 py-3 rounded-lg shadow-md inline-block mb-6">
          {blogCategory && <span>{blogCategory.name} | </span>}
          公開日: {publishDate}
          {updatedAt && updatedAt !== publishDate && (
            <> | 更新日: {updatedAt}</>
          )}
        </div>

        {/* ✅ タグ（黒枠のみ、現在のデザインを維持） */}
        {blogTags && blogTags.length > 0 && (
          <div className="flex flex-wrap gap-3 mb-8">
            {blogTags.map((tag) => (
              <span
                key={tag.name}
                className="border-2 border-black text-black text-lg font-medium px-3 py-1 rounded-full"
              >
                #{tag.name}
              </span>
            ))}
          </div>
        )}

        <div className="text-2xl leading-relaxed space-y-8">
          {documentToReactComponents(
            JSON.parse(body.raw),
            richTextOptionsWithReferences
          )}
        </div>

        {author && (
          <div className="mt-20 p-12 rounded-2xl shadow-xl max-w-screen-lg w-full mx-auto border border-gray-400">
            {/* タイトル */}
            <h3 className="text-4xl font-bold text-gray-900 dark:text-white mb-6 pb-3 border-b-4 border-gray-400">
              この記事を書いた人
            </h3>

            <div className="flex flex-col md:flex-row items-center md:items-start space-y-4 md:space-y-0 md:space-x-6">
              {/* 著者アイコン */}
              <div className="w-44 h-44 flex-shrink-0">
                <GatsbyImage
                  image={getImage(author.avatar)}
                  alt={author.name}
                  loading="lazy"
                  placeholder="blurred"
                  className="rounded-2xl border-4 border-gray-500 shadow-xl object-contain"
                />
              </div>

              {/* 著者情報（名前 & Bio） */}
              <div className="text-left flex-1">
                <h3 className="text-3xl font-bold">{author.name}</h3>
                <p className="text-2xl text-gray-700">{author.bio}</p>
              </div>
            </div>
          </div>
        )}
        <RelatedBlogs
          tags={post.blogTags.map((tag) => tag.name)}
          category={post.blogCategory?.name}
          currentPostId={post.id} // ✅ ここを正しく渡す
        />
      </article>
      <Breadcrumbs breadcrumbList={breadcrumbList} />
    </Layout>
  );
};

export const query = graphql`
  query BlogPostBySlug($id: String!) {
    contentfulBlogPage(id: { eq: $id }) {
      id
      title
      slug
      publishDate(formatString: "YYYY年MM月DD日")
      updatedAt(formatString: "YYYY-MM-DD")
      blogCategory {
        # ✅ カテゴリーの名前を取得
        name
      }
      blogTags {
        # ✅ タグの名前を取得
        name
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            file {
              url
            }
            title
          }
        }
      }
      image {
        url
        gatsbyImageData(
          layout: CONSTRAINED
          width: 800 # ✅ 最大 800px まで拡大可能（スマホ対応）
          quality: 80
          formats: [AUTO, WEBP, AVIF]
        )
      }
      seotitle
      seodescription
      author {
        # ✅ 著者情報を追加で取得！
        name
        type # Person or Organization
        slug
        profileUrl
        sameAs
        avatar {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 176 # ✅ 幅176px（現在のデザインに合わせる）
            height: 176 # ✅ 高さ176px（正方形にする）
            quality: 80
            formats: [AUTO, WEBP, AVIF] # ✅ 最適フォーマットを適用
          )
        }
        bio
      }
    }
  }
`;

export default BlogPost;
