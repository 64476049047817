import React from 'react';
import { Helmet } from 'react-helmet';

const AuthorSchema = ({ author, title, publishDate, updatedAt }) => {
  if (!author || !author.name) {
    return null;
  }

  const authorData = {
    '@type': author.type, // "Person" or "Organization"
    name: author.name,
    url: author.profileUrl,
    image: author.avatar?.url,
    sameAs: author.sameAs,
  };

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    headline: title,
    datePublished: publishDate,
    dateModified: updatedAt || publishDate,
    author: authorData, // ✅ ここで著者情報をセット！
    publisher: {
      '@type': 'Organization',
      name: 'INVOLVE',
      logo: {
        '@type': 'ImageObject',
        url: 'https://www.involve-in.jp/images/logo.png',
      },
    },
  };

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};

export default AuthorSchema;
